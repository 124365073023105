import authAxios from 'src/modules/shared/axios/authAxios';

export default class DescuentosService {
  static async edit(data, id) {
   
    const response = await authAxios.put(
      `/descuentos/${id}`,
      data,
    );

    return response.data;
  }

  static async destroy(id) {
   

    const response = await authAxios.delete(
      `/descuentos/${id}`,
    );

    return response.data;
  }

  static async create(data) {

    const response = await authAxios.post(
      `/descuentos`,
      data,
    );

    return response.data;
  }

  static async find(id) {
    const response = await authAxios.get(
      `/descuentos/${id}`,
    );
    return response.data;
  }

  static async fetchDescuentos(filter, orderBy, limit, offset) {
    const params = {
      filter,
      sort: orderBy,
      size: limit,
      page: offset,
    };

    const response = await authAxios.get(
      `/descuentos/`,
      {
        params,
      },
    );

    return response.data;
  }
  static async fetchTipoDescuentos(filter, orderBy, limit, offset) {
    const params = {
      filter,
      sort: orderBy,
      size: limit,
      page: offset,
    };

    const response = await authAxios.get(
      `/descuentos/tipos`,
      {
        params,
      },
    );

    return response.data;
  }
  static async fetchDescuentosAutocomplete(query, limit) {
    const params = {
      query,
      limit,
    };

    const response = await authAxios.get(
      `/descuentos/autocomplete`,
      {
        params,
      },
    );
    return response.data;
  }
}

import Permissions from 'src/security/permissions';
const permissions = Permissions.values;

const privateRoutes = [
  {
    path: '/',
    loader: () =>
      import('src/view/dashboard/DashboardPage'),
    permissionRequired: null,
    exact: true,
  },

  {
    path: '/user',
    loader: () => import('src/view/user/list/UserPage'),
    permissionRequired: permissions.userRead,
    exact: true,
  },

  {
    path: '/user/new',
    loader: () => import('src/view/user/new/UserNewPage'),
    permissionRequired: permissions.userCreate,
    exact: true,
  },

  {
    path: '/user/:id/edit',
    loader: () => import('src/view/user/edit/UserEditPage'),
    permissionRequired: permissions.userRead,
    exact: true,
  },
  {
    path: '/user',
    loader: () => import('src/view/user/list/UserPage'),
    permissionRequired: permissions.userRead,
    exact: true,
  },
  
  {
    path: '/profile',
    loader: () => import('src/view/auth/profile/view/ProfileViewPage'),
    permissionRequired: permissions.userRead,
    exact: true,
  },
  {
    path: '/profile/:id/edit',
    loader: () => import('src/view/auth/profile/edit/ProfileEditPage'),
    permissionRequired: permissions.userRead,
    exact: true,
  },
  {
    path: '/user/:id',
    loader: () => import('src/view/user/view/UserViewPage'),
    permissionRequired: permissions.userRead,
    exact: true,
  },
  //Capítulos
  {
    path: '/capitulos',
    loader: () => import('src/view/capitulos/list/CapitulosPage'),
    permissionRequired: permissions.userRead,
    exact: true,
  },

  {
    path: '/capitulos/new',
    loader: () => import('src/view/capitulos/new/CapitulosNewPage'),
    permissionRequired: permissions.userRead,
    exact: true,
  },

  {
    path: '/capitulos/:id/edit',
    loader: () => import('src/view/capitulos/edit/CapitulosEditPage'),
    permissionRequired: permissions.userRead,
    exact: true,
  },

  {
    path: '/capitulos/:id',
    loader: () => import('src/view/capitulos/view/CapitulosViewPage'),
    permissionRequired: permissions.userRead,
    exact: true,
  },
   //Listas
   {
    path: '/listas-proveedores',
    loader: () => import('src/view/listas/list/ListasPage'),
    permissionRequired: permissions.userRead,
    exact: true,
  },

  {
    path: '/listas-proveedores/new',
    loader: () => import('src/view/listas/new/ListasNewPage'),
    permissionRequired: permissions.userRead,
    exact: true,
  },

  {
    path: '/listas-proveedores/:id/edit',
    loader: () => import('src/view/listas/edit/ListasEditPage'),
    permissionRequired: permissions.userRead,
    exact: true,
  },

  {
    path: '/listas-proveedores/:id',
    loader: () => import('src/view/listas/view/ListasViewPage'),
    permissionRequired: permissions.userRead,
    exact: true,
  },
  //Lista Kelvineu
  {
    path: '/listas-kelvineu',
    loader: () => import('src/view/listasKelvineu/list/ListasKelvineuPage'),
    permissionRequired: permissions.userRead,
    exact: true,
  },

  {
    path: '/listas-kelvineu/new',
    loader: () => import('src/view/listasKelvineu/new/ListasKelvineuNewPage'),
    permissionRequired: permissions.userCreate,
    exact: true,
  },

  {
    path: '/listas-kelvineu/:id/edit',
    loader: () => import('src/view/listasKelvineu/edit/ListasKelvineuEditPage'),
    permissionRequired: permissions.userRead,
    exact: true,
  },

  {
    path: '/listas-kelvineu/:id',
    loader: () => import('src/view/listasKelvineu/view/ListasKelvineuViewPage'),
    permissionRequired: permissions.userRead,
    exact: true,
  },
  //Configuraciones
  {
    path: '/configuraciones',
    loader: () => import('src/view/config/list/ConfigPage'),
    permissionRequired: permissions.userRead,
    exact: true,
  },

  {
    path: '/configuraciones/new',
    loader: () => import('src/view/config/new/ConfigNewPage'),
    permissionRequired: permissions.userCreate,
    exact: true,
  },

  {
    path: '/configuraciones/:id/edit',
    loader: () => import('src/view/config/edit/ConfigEditPage'),
    permissionRequired: permissions.userRead,
    exact: true,
  },

  {
    path: '/configuraciones/:id',
    loader: () => import('src/view/config/view/ConfigViewPage'),
    permissionRequired: permissions.userRead,
    exact: true,
  },

  //Desplazamientos
  {
    path: '/desplazamientos',
    loader: () => import('src/view/desplazamientos/list/DesplazamientosPage'),
    permissionRequired: permissions.userRead,
    exact: true,
  },

  {
    path: '/desplazamientos/new',
    loader: () => import('src/view/desplazamientos/new/DesplazamientosNewPage'),
    permissionRequired: permissions.userCreate,
    exact: true,
  },

  {
    path: '/desplazamientos/:id/edit',
    loader: () => import('src/view/desplazamientos/edit/DesplazamientosEditPage'),
    permissionRequired: permissions.userRead,
    exact: true,
  },

  {
    path: '/desplazamientos/:id',
    loader: () => import('src/view/desplazamientos/view/DesplazamientosViewPage'),
    permissionRequired: permissions.userRead,
    exact: true,
  },
 //Compuestos
 {
  path: '/compuestos',
  loader: () => import('src/view/compuestos/list/CompuestosPage'),
  permissionRequired: permissions.userRead,
  exact: true,
},

{
  path: '/compuestos/new',
  loader: () => import('src/view/compuestos/new/CompuestosNewPage'),
  permissionRequired: permissions.userCreate,
  exact: true,
},

{
  path: '/compuestos/:id/edit',
  loader: () => import('src/view/compuestos/edit/CompuestosEditPage'),
  permissionRequired: permissions.userRead,
  exact: true,
},

{
  path: '/compuestos/:id',
  loader: () => import('src/view/compuestos/view/CompuestosViewPage'),
  permissionRequired: permissions.userRead,
  exact: true,
},

 //presupuesto
 {
  path: '/presupuesto',
  loader: () => import('src/view/presupuesto/list/PresupuestoPage'),
  permissionRequired: permissions.userRead,
  exact: true,
},

{
  path: '/presupuesto/new',
  loader: () => import('src/view/presupuesto/new/PresupuestoNewPage'),
  permissionRequired: permissions.userRead,
  exact: true,
},

{
  path: '/presupuesto/:id/edit',
  loader: () => import('src/view/presupuesto/edit/PresupuestoEditPage'),
  permissionRequired: permissions.userRead,
  exact: true,
},

  //Descuentos
  {
    path: '/mano-de-obra',
    loader: () => import('src/view/mdo/list/MDOPage'),
    permissionRequired: permissions.userRead,
    exact: true,
  },

  {
    path: '/mano-de-obra/new',
    loader: () => import('src/view/mdo/new/MDONewPage'),
    permissionRequired: permissions.userCreate,
    exact: true,
  },

  {
    path: '/mano-de-obra/:id/edit',
    loader: () => import('src/view/mdo/edit/MDOEditPage'),
    permissionRequired: permissions.userRead,
    exact: true,
  },

  {
    path: '/mano-de-obra/:id',
    loader: () => import('src/view/mdo/view/MDOViewPage'),
    permissionRequired: permissions.userRead,
    exact: true,
  },

  //Proveedores
  {
    path: '/proveedores',
    loader: () => import('src/view/proveedores/list/ProveedoresPage'),
    permissionRequired: permissions.userRead,
    exact: true,
  },

  {
    path: '/proveedores/new',
    loader: () => import('src/view/proveedores/new/ProveedoresNewPage'),
    permissionRequired: permissions.userCreate,
    exact: true,
  },

  {
    path: '/proveedores/:id/edit',
    loader: () => import('src/view/proveedores/edit/ProveedoresEditPage'),
    permissionRequired: permissions.userRead,
    exact: true,
  },

  {
    path: '/proveedores/:id',
    loader: () => import('src/view/proveedores/view/ProveedoresViewPage'),
    permissionRequired: permissions.userRead,
    exact: true,
  },
].filter(Boolean);

const publicRoutes = [
  {
    path: '/auth/signin',
    loader: () => import('src/view/auth/SigninPage'),
  },
  {
    path: '/account/password/reset',
    loader: () => import('src/view/auth/ForgetPassword'),
    permissionRequired: permissions.userRead,
    exact: true,
  },
].filter(Boolean);

const emptyPermissionsRoutes = [
  {
    path: '/auth/empty-permissions',
    loader: () =>
      import('src/view/auth/EmptyPermissionsPage'),
  },
].filter(Boolean);

const simpleRoutes = [
  {
    path: '/403',
    loader: () =>
      import('src/view/shared/errors/Error403Page'),
  },
  {
    path: '/500',
    loader: () =>
      import('src/view/shared/errors/Error500Page'),
  },
  {
    path: '**',
    loader: () =>
      import('src/view/shared/errors/Error404Page'),
  },
].filter(Boolean);

export default {
  privateRoutes,
  publicRoutes,
  emptyPermissionsRoutes,
  simpleRoutes,
};

import Errors from 'src/modules/shared/error/errors';
import { getHistory } from 'src/modules/store';
import listasKelvineuService from 'src/modules/listasKelvineu/listasKelvineuService';

const prefix = 'LISTAS_KELVINEU_VIEW';

const listasKelvineuViewActions = {
  FIND_STARTED: `${prefix}_FIND_STARTED`,
  FIND_SUCCESS: `${prefix}_FIND_SUCCESS`,
  FIND_ERROR: `${prefix}_FIND_ERROR`,

  doFind: (id) => async (dispatch) => {
    try {
      dispatch({
        type: listasKelvineuViewActions.FIND_STARTED,
      });

      const listasKelvineu = await listasKelvineuService.find(id);

      dispatch({
        type: listasKelvineuViewActions.FIND_SUCCESS,
        payload: listasKelvineu,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: listasKelvineuViewActions.FIND_ERROR,
      });

      getHistory().push('/listas-kelvineu');
    }
  },
};

export default listasKelvineuViewActions;

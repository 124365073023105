import list from 'src/modules/compuestos/list/compuestosListReducers';
import form from 'src/modules/compuestos/form/compuestosFormReducers';
import view from 'src/modules/compuestos/view/compuestosViewReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
});

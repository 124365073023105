import authAxios from 'src/modules/shared/axios/authAxios';

export default class ListasService {
  static async edit(data, id) {


    const response = await authAxios.put(
      `/materiales/${id}`,
      data,
    );

    return response.data;
  }

  static async destroy(ids) {
    const params = {
      ids,
    };

    const response = await authAxios.delete(
      `/materiales`,
      {
        params,
      },
    );

    return response.data;
  }
  static async create(data) {

    const response = await authAxios.post(
      `/materiales`,
      data,
    );

    return response.data;
  }

  static async find(id) {
    const response = await authAxios.get(
      `/materiales/${id}`,
    );
    return response.data;
  }

  static async fetchListas(filter, orderBy, limit, offset) {
    const params = {
      filter,
      sort: orderBy,
      size: limit,
      page: offset,
    };

    const response = await authAxios.get(
      `/materiales/`,
      {
        params,
      },
    );

    return response.data;
  }
  static async fetchAll(filter, orderBy, limit, offset) {
    const params = {
      filter,
      sort: orderBy,
      size: limit,
      page: offset,
    };

    const response = await authAxios.get(
      `/materiales/all`,
      {
        params,
      },
    );

    return response.data;
  }

  static async fetchListasAutocomplete(query, limit) {
    const params = {
      query,
      limit,
    };

    const response = await authAxios.get(
      `/materiales/autocomplete`,
      {
        params,
      },
    );
    return response.data;
  }  
  static async import( data) {

    const response = await authAxios.post(
      `/materiales/alta_masiva/`,
      data,
    );
    
    return response.data;
  }
}

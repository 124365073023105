import list from 'src/modules/listas/list/listasListReducers';
import form from 'src/modules/listas/form/listasFormReducers';
import view from 'src/modules/listas/view/listasViewReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
});

import Permissions from 'src/security/permissions';
import { i18n } from 'src/i18n';
import React from 'react';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PersonIcon from '@mui/icons-material/Person';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import SettingsIcon from '@mui/icons-material/Settings';
import InventoryIcon from '@mui/icons-material/Inventory';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';

const permissions = Permissions.values;

export default [
  {
    path: '/presupuesto',
    label: i18n('Presupuestos'),
    permissionRequired: permissions.userRead,
    icon: <MonetizationOnIcon />,
  },
  {
    path: '/capitulos',
    label: i18n('Capítulos'),
    permissionRequired: permissions.userRead,
    icon: <ReceiptLongIcon />,
  },
  {
    path: '/compuestos',
    label: i18n('Compuestos y Ensamblados'),
    permissionRequired: permissions.userRead,
    icon: <InventoryIcon />,
  },
  {
    path: '/listas-kelvineu',
    label: i18n('Lista Kelvineu'),
    permissionRequired: permissions.userRead,
    icon: <AssignmentIcon />,
  }, 
  {
    path: '/listas-proveedores',
    label: i18n('Lista Proveedores'),
    permissionRequired: permissions.userRead,
    icon: <AssignmentIcon />,
  }, 
  {
    path: '',
    label: i18n('Otras Listas'),
    permissionRequired: permissions.userRead,
    icon: <AssignmentIcon />,
    subMenu: [
      {
        path: '/mano-de-obra',
        exact: true,
        icon: null,
        label: i18n('Mano de obra'),
        permissionRequired: permissions.userRead,
      },
      {
        path: '/desplazamientos',
        exact: true,
        icon: null,
        label: i18n('Desplazamientos'),
        permissionRequired: permissions.userRead,
      },
    ]
  },
  {
    path: '/configuraciones',
    label: i18n('Configuraciones'),
    permissionRequired: permissions.userRead,
    icon: <SettingsIcon />,
  },
  {
    path: '/proveedores',
    label: i18n('Proveedores'),
    permissionRequired: permissions.userRead,
    icon: <LocalShippingIcon />,
  },
  {
    path: '/user',
    label: i18n('user.menu'),
    permissionRequired: permissions.userEdit,
    icon: <PersonIcon />,
  },
].filter(Boolean);

import list from 'src/modules/presupuesto/list/presupuestoListReducers';
import form from 'src/modules/presupuesto/form/presupuestoFormReducers';
import view from 'src/modules/presupuesto/view/presupuestoViewReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
});

const backendUrl = 'http://kelvineu-api.jedify.io/';

const frontendUrl = {
  host: 'http://kelvineu2.jedify.io',
  protocol: 'http',
};

export default {
  frontendUrl,
  backendUrl,
};

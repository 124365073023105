import ListasService from 'src/modules/listas/listasService';
import selectors from 'src/modules/listas/list/listasListSelectors';
import Errors from 'src/modules/shared/error/errors';
import Message from 'src/view/shared/message';
import { i18n } from 'src/i18n';

const prefix = 'LISTAS_LIST';

const listasListActions = {
  FETCH_STARTED: `${prefix}_FETCH_STARTED`,
  FETCH_SUCCESS: `${prefix}_FETCH_SUCCESS`,
  FETCH_ERROR: `${prefix}_FETCH_ERROR`,

  RESETED: `${prefix}_RESETED`,
  TOGGLE_ONE_SELECTED: `${prefix}_TOGGLE_ONE_SELECTED`,
  TOGGLE_ALL_SELECTED: `${prefix}_TOGGLE_ALL_SELECTED`,
  CLEAR_ALL_SELECTED: `${prefix}_CLEAR_ALL_SELECTED`,

  PAGINATION_CHANGED: `${prefix}_PAGINATION_CHANGED`,
  SORTER_CHANGED: `${prefix}_SORTER_CHANGED`,

  DESTROY_ALL_SELECTED_STARTED: `${prefix}_DESTROY_ALL_SELECTED_STARTED`,
  DESTROY_ALL_SELECTED_SUCCESS: `${prefix}_DESTROY_ALL_SELECTED_SUCCESS`,
  DESTROY_ALL_SELECTED_ERROR: `${prefix}_DESTROY_ALL_SELECTED_ERROR`,

  DESTROY_STARTED: `${prefix}_DESTROY_STARTED`,
  DESTROY_SUCCESS: `${prefix}_DESTROY_SUCCESS`,
  DESTROY_ERROR: `${prefix}_DESTROY_ERROR`,

  IMPORT_STARTED: `${prefix}_IMPORT_STARTED`,
  IMPORT_SUCCESS: `${prefix}_IMPORT_SUCCESS`,
  IMPORT_ERROR: `${prefix}_IMPORT_ERROR`,

  doClearAllSelected() {
    return {
      type: listasListActions.CLEAR_ALL_SELECTED,
    };
  },

  doToggleAllSelected() {
    return {
      type: listasListActions.TOGGLE_ALL_SELECTED,
    };
  },

  doToggleOneSelected(id) {
    return {
      type: listasListActions.TOGGLE_ONE_SELECTED,
      payload: id,
    };
  },

  doReset: (filter) => async (dispatch) => {
    dispatch({
      type: listasListActions.RESETED,
    });

    dispatch(listasListActions.doFetch(filter));
  },

  doChangePagination: (pagination) => async (
    dispatch,
    getState,
  ) => {
    dispatch({
      type: listasListActions.PAGINATION_CHANGED,
      payload: pagination,
    });

    dispatch(listasListActions.doFetchCurrentFilter());
  },

  doChangeSort: (sorter) => async (dispatch, getState) => {
    dispatch({
      type: listasListActions.SORTER_CHANGED,
      payload: sorter,
    });

    dispatch(listasListActions.doFetchCurrentFilter());
  },

  doFetchCurrentFilter: () => async (
    dispatch,
    getState,
  ) => {
    const filter = selectors.selectFilter(getState());
    const rawFilter = selectors.selectRawFilter(getState());
    dispatch(listasListActions.doFetch(filter, rawFilter, true));
  },

  doFetch: (filter?, rawFilter?, keepPagination = false) => async (
    dispatch,
    getState,
  ) => {
    try {
      dispatch({
        type: listasListActions.FETCH_STARTED,
        payload: { filter, rawFilter, keepPagination },
      });

      const response = await ListasService.fetchListas(
        filter,
        selectors.selectOrderBy(getState()),
        selectors.selectLimit(getState()),
        selectors.selectOffset(getState()),
      );
      dispatch({
        type: listasListActions.FETCH_SUCCESS,
        payload: {
          rows: response.rows,
          count: response.count,
        },
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: listasListActions.FETCH_ERROR,
      });
    }
  },
  doFetchAll: (filter?, rawFilter?, keepPagination = false) => async (
    dispatch,
    getState,
  ) => {
    try {
      dispatch({
        type: listasListActions.FETCH_STARTED,
        payload: { filter, rawFilter, keepPagination },
      });

      const response = await ListasService.fetchAll(
        filter,
        selectors.selectOrderBy(getState()),
        selectors.selectLimit(getState()),
        selectors.selectOffset(getState()),
      );
      dispatch({
        type: listasListActions.FETCH_SUCCESS,
        payload: {
          rows: response.rows,
          count: response.count,
        },
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: listasListActions.FETCH_ERROR,
      });
    }
  },

  doFetchOptions: (filter?, rawFilter?,options?, keepPagination = false) => async (
    dispatch,
    getState,
  ) => {
    try {
      dispatch({
        type: listasListActions.FETCH_STARTED,
        payload: { filter, rawFilter, keepPagination },
      });

      const response = await ListasService.fetchListas(
        filter,
        selectors.selectOrderBy(getState()),
        options ? null : selectors.selectLimit(getState()),
        selectors.selectOffset(getState()),
      );
      dispatch({
        type: listasListActions.FETCH_SUCCESS,
        payload: {
          rows: response.rows,
          count: response.count,
        },
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: listasListActions.FETCH_ERROR,
      });
    }
  },
  doDestroy: (id, restore) => async (dispatch, getState) => {
    try {
      dispatch({
        type: listasListActions.DESTROY_STARTED,
      });

      await ListasService.destroy(id);

      dispatch({
        type: listasListActions.DESTROY_SUCCESS,
      });

      if (restore) {
        Message.success(i18n('Material habilitado exitosamente'));
      } else {Message.success(i18n('Material inhabilitado exitosamente')); }

      dispatch(listasListActions.doFetchCurrentFilter());
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: listasListActions.DESTROY_ERROR,
      });

      dispatch(listasListActions.doFetchCurrentFilter());
    }
  },

  doDestroyAllSelected: () => async (
    dispatch,
    getState,
  ) => {
    try {
      const selectedRows = selectors.selectSelectedRows(
        getState(),
      );

      dispatch({
        type: listasListActions.DESTROY_ALL_SELECTED_STARTED,
      });

      await ListasService.destroy(
        selectedRows.map((row) => row.id),
      );

      dispatch({
        type: listasListActions.DESTROY_ALL_SELECTED_SUCCESS,
      });

      Message.success(
        i18n('listas.doDestroyAllSelectedSuccess'),
      );

      dispatch(listasListActions.doFetchCurrentFilter());
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: listasListActions.DESTROY_ALL_SELECTED_ERROR,
      });

      dispatch(listasListActions.doFetchCurrentFilter());
    }
  },
  doImport: (data) => async (dispatch) => {
    try {
      dispatch({
        type: listasListActions.IMPORT_STARTED,
      });

      await ListasService.import(data);
     

      dispatch({
        type: listasListActions.IMPORT_SUCCESS,
      });


      
      (window as any).location.reload();
      Message.success(i18n('importer.completed.success'),);

    } catch (error) {
      Errors.handle('Error en el alta');
      console.log(error)
      dispatch({
        type: listasListActions.IMPORT_ERROR,
      });
    }
  },
};

export default listasListActions;

import Errors from 'src/modules/shared/error/errors';
import { getHistory } from 'src/modules/store';
import configService from 'src/modules/config/configService';

const prefix = 'USER_VIEW';

const configViewActions = {
  FIND_STARTED: `${prefix}_FIND_STARTED`,
  FIND_SUCCESS: `${prefix}_FIND_SUCCESS`,
  FIND_ERROR: `${prefix}_FIND_ERROR`,

  doFind: (id) => async (dispatch) => {
    try {
      dispatch({
        type: configViewActions.FIND_STARTED,
      });

      const config = await configService.find(id);

      dispatch({
        type: configViewActions.FIND_SUCCESS,
        payload: config,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: configViewActions.FIND_ERROR,
      });

      getHistory().push('/configuraciones');
    }
  },
};

export default configViewActions;

import DesplazamientosService from 'src/modules/desplazamientos/desplazamientosService';
import selectors from 'src/modules/desplazamientos/list/desplazamientosListSelectors';
import Errors from 'src/modules/shared/error/errors';
import Message from 'src/view/shared/message';
import { i18n } from 'src/i18n';

const prefix = 'DESPLAZAMIENTOS_LIST';

const desplazamientosListActions = {
  FETCH_STARTED: `${prefix}_FETCH_STARTED`,
  FETCH_SUCCESS: `${prefix}_FETCH_SUCCESS`,
  FETCH_ERROR: `${prefix}_FETCH_ERROR`,

  RESETED: `${prefix}_RESETED`,
  TOGGLE_ONE_SELECTED: `${prefix}_TOGGLE_ONE_SELECTED`,
  TOGGLE_ALL_SELECTED: `${prefix}_TOGGLE_ALL_SELECTED`,
  CLEAR_ALL_SELECTED: `${prefix}_CLEAR_ALL_SELECTED`,

  PAGINATION_CHANGED: `${prefix}_PAGINATION_CHANGED`,
  SORTER_CHANGED: `${prefix}_SORTER_CHANGED`,

  DESTROY_ALL_SELECTED_STARTED: `${prefix}_DESTROY_ALL_SELECTED_STARTED`,
  DESTROY_ALL_SELECTED_SUCCESS: `${prefix}_DESTROY_ALL_SELECTED_SUCCESS`,
  DESTROY_ALL_SELECTED_ERROR: `${prefix}_DESTROY_ALL_SELECTED_ERROR`,

  DESTROY_STARTED: `${prefix}_DESTROY_STARTED`,
  DESTROY_SUCCESS: `${prefix}_DESTROY_SUCCESS`,
  DESTROY_ERROR: `${prefix}_DESTROY_ERROR`,

  doClearAllSelected() {
    return {
      type: desplazamientosListActions.CLEAR_ALL_SELECTED,
    };
  },

  doToggleAllSelected() {
    return {
      type: desplazamientosListActions.TOGGLE_ALL_SELECTED,
    };
  },

  doToggleOneSelected(id) {
    return {
      type: desplazamientosListActions.TOGGLE_ONE_SELECTED,
      payload: id,
    };
  },

  doReset: () => async (dispatch) => {
    dispatch({
      type: desplazamientosListActions.RESETED,
    });

    dispatch(desplazamientosListActions.doFetch());
  },

  doChangePagination: (pagination) => async (
    dispatch,
    getState,
  ) => {
    dispatch({
      type: desplazamientosListActions.PAGINATION_CHANGED,
      payload: pagination,
    });

    dispatch(desplazamientosListActions.doFetchCurrentFilter());
  },

  doChangeSort: (sorter) => async (dispatch, getState) => {
    dispatch({
      type: desplazamientosListActions.SORTER_CHANGED,
      payload: sorter,
    });

    dispatch(desplazamientosListActions.doFetchCurrentFilter());
  },

  doFetchCurrentFilter: () => async (
    dispatch,
    getState,
  ) => {
    const filter = selectors.selectFilter(getState());
    const rawFilter = selectors.selectRawFilter(getState());
    dispatch(desplazamientosListActions.doFetch(filter, rawFilter, true));
  },

  doFetch: (filter?, rawFilter?, keepPagination = false) => async (
    dispatch,
    getState,
  ) => {
    try {
      dispatch({
        type: desplazamientosListActions.FETCH_STARTED,
        payload: { filter, rawFilter, keepPagination },
      });

      const response = await DesplazamientosService.fetchDesplazamientos(
        filter,
        selectors.selectOrderBy(getState()),
        selectors.selectLimit(getState()),
        selectors.selectOffset(getState()),
      );
      dispatch({
        type: desplazamientosListActions.FETCH_SUCCESS,
        payload: {
          rows: response.rows,
          count: response.count,
        },
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: desplazamientosListActions.FETCH_ERROR,
      });
    }
  },
  doDestroy: (id, restore) => async (dispatch, getState) => {
    try {
      dispatch({
        type: desplazamientosListActions.DESTROY_STARTED,
      });

      await DesplazamientosService.destroy(id);

      dispatch({
        type: desplazamientosListActions.DESTROY_SUCCESS,
      });

      
      if (restore) {
        Message.success(i18n('Desplazamiento habilitado exitosamente'));
      } else {Message.success(i18n('Desplazamiento inhabilitado exitosamente')); }

      dispatch(desplazamientosListActions.doFetchCurrentFilter());
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: desplazamientosListActions.DESTROY_ERROR,
      });

      dispatch(desplazamientosListActions.doFetchCurrentFilter());
    }
  },

  doDestroyAllSelected: () => async (
    dispatch,
    getState,
  ) => {
    try {
      const selectedRows = selectors.selectSelectedRows(
        getState(),
      );

      dispatch({
        type: desplazamientosListActions.DESTROY_ALL_SELECTED_STARTED,
      });

      await DesplazamientosService.destroy(
        selectedRows.map((row) => row.id),
      );

      dispatch({
        type: desplazamientosListActions.DESTROY_ALL_SELECTED_SUCCESS,
      });

      Message.success(
        i18n('desplazamientos.doDestroyAllSelectedSuccess'),
      );

      dispatch(desplazamientosListActions.doFetchCurrentFilter());
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: desplazamientosListActions.DESTROY_ALL_SELECTED_ERROR,
      });

      dispatch(desplazamientosListActions.doFetchCurrentFilter());
    }
  },
};

export default desplazamientosListActions;

import authAxios from 'src/modules/shared/axios/authAxios';

export default class PresupuestoService {
  static async edit(data, id) {


    const response = await authAxios.put(
      `/presupuesto/${id}`,
      data,
    );

    return response.data;
  }

  static async destroy(ids) {
    const params = {
      ids,
    };

    const response = await authAxios.delete(
      `/presupuesto/`,
      {
        params,
      },
    );

    return response.data;
  }

  static async create(data) {

    const response = await authAxios.post(
      `/presupuesto`,
      data,
    );

    return response.data;
  }

  static async find(id) {
    const response = await authAxios.get(
      `/presupuesto/${id}`,
    );
    return response.data;
  }

  static async findFile(id) {
    const response = await authAxios.get(
      `/presupuesto/getFile/${id}`,
    );
    return response.data;
  }
  static async fetchPresupuestos(filter, orderBy, limit, offset) {
    const params = {
      filter,
      sort: orderBy,
      size: limit,
      page: offset,
    };

    const response = await authAxios.get(
      `/presupuesto/`,
      {
        params,
      },
    );

    return response.data;
  }

  static async fetchPresupuestoAutocomplete(query, limit) {
    const params = {
      query,
      limit,
    };

    const response = await authAxios.get(
      `/presupuesto/autocomplete`,
      {
        params,
      },
    );
    return response.data;
  }
}

import list from 'src/modules/mdo/list/mdoListReducers';
import form from 'src/modules/mdo/form/mdoFormReducers';
import view from 'src/modules/mdo/view/mdoViewReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
});

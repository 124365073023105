import Errors from "src/modules/shared/error/errors";
import Message from "src/view/shared/message";
import CapitulosService from "src/modules/capitulos/capitulosService";
import { getHistory } from "src/modules/store";
import { i18n } from "src/i18n";
import authSelectors from "src/modules/auth/authSelectors";
import authActions from "src/modules/auth/authActions";

const prefix = "CAPITULOS_FORM";

const capitulosFormActions = {
  INIT_STARTED: `${prefix}_INIT_STARTED`,
  INIT_SUCCESS: `${prefix}_INIT_SUCCESS`,
  INIT_ERROR: `${prefix}_INIT_ERROR`,

  ADD_STARTED: `${prefix}_ADD_STARTED`,
  ADD_SUCCESS: `${prefix}_ADD_SUCCESS`,
  ADD_ERROR: `${prefix}_ADD_ERROR`,

  UPDATE_STARTED: `${prefix}_UPDATE_STARTED`,
  UPDATE_SUCCESS: `${prefix}_UPDATE_SUCCESS`,
  UPDATE_ERROR: `${prefix}_UPDATE_ERROR`,

  doInit: (id?) => async (dispatch) => {
    try {
      dispatch({
        type: capitulosFormActions.INIT_STARTED,
      });

      const isEdit = Boolean(id);
      let record = {};

      if (isEdit) {
        record = await CapitulosService.find(id);
      }

      dispatch({
        type: capitulosFormActions.INIT_SUCCESS,
        payload: record,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: capitulosFormActions.INIT_ERROR,
      });

      getHistory().push("/capitulos");
    }
  },

  doAdd: (values, edit?, index?) => async (dispatch) => {
    try {
      dispatch({
        type: capitulosFormActions.ADD_STARTED,
        payload: index,
      });

      const capitulos = await CapitulosService.create(values);
      dispatch({
        type: capitulosFormActions.ADD_SUCCESS,
        payload: capitulos,
      });
      Message.success(i18n("Capítulo agregado correctamente"));

      {
        !edit && getHistory().push("/capitulos");
      }
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: capitulosFormActions.ADD_ERROR,
      });
    }
  },

  doUpdate: (values, id) => async (dispatch, getState) => {
    try {
      dispatch({
        type: capitulosFormActions.UPDATE_STARTED,
      });

      await CapitulosService.edit(values, id);

      dispatch({
        type: capitulosFormActions.UPDATE_SUCCESS,
      });

      const currentcapitulos = authSelectors.selectCurrentUser(getState());

      if (currentcapitulos.id === values.id) {
        await dispatch(authActions.doRefreshCurrentUser());
      }

      Message.success(i18n("Capítulo modificado correctamente"));
      getHistory().push("/capitulos");
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: capitulosFormActions.UPDATE_ERROR,
      });
    }
  },
};

export default capitulosFormActions;

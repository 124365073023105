import actions from "src/modules/capitulos/form/capitulosFormActions";

const initialData = {
  initLoading: false,
  saveLoading: false,
  indexCapitulo: null,
  capitulos: null,
  capituloCreated: null,
  flag: false,
};

export default (state = initialData, { type, payload }) => {
  if (type === actions.INIT_STARTED) {
    return {
      ...state,
      capitulos: null,
      initLoading: true,
    };
  }

  if (type === actions.INIT_SUCCESS) {
    return {
      ...state,
      capitulos: payload,
      initLoading: false,
    };
  }

  if (type === actions.INIT_ERROR) {
    return {
      ...state,
      capitulos: null,
      initLoading: false,
    };
  }

  if (type === actions.ADD_STARTED) {
    return {
      ...state,
      saveLoading: true,
      indexCapitulo: payload
    };
  }

  if (type === actions.ADD_SUCCESS) {
    return {
      ...state,
      capituloCreated: payload,
      saveLoading: false,
      flag: true,
    };
  }

  if (type === actions.ADD_ERROR) {
    return {
      ...state,
      saveLoading: false,
    };
  }

  if (type === actions.UPDATE_STARTED) {
    return {
      ...state,
      saveLoading: true,
    };
  }

  if (type === actions.UPDATE_SUCCESS) {
    return {
      ...state,
      saveLoading: false,
    };
  }

  if (type === actions.UPDATE_ERROR) {
    return {
      ...state,
      saveLoading: false,
    };
  }

  return state;
};

import Errors from 'src/modules/shared/error/errors';
import Message from 'src/view/shared/message';
import ProveedoresService from 'src/modules/proveedores/proveedoresService';
import { getHistory } from 'src/modules/store';
import { i18n } from 'src/i18n';
import authSelectors from 'src/modules/auth/authSelectors';
import authActions from 'src/modules/auth/authActions';

const prefix = 'DESCUENTOS_FORM';

const proveedoresFormActions = {
  INIT_STARTED: `${prefix}_INIT_STARTED`,
  INIT_SUCCESS: `${prefix}_INIT_SUCCESS`,
  INIT_ERROR: `${prefix}_INIT_ERROR`,

  ADD_STARTED: `${prefix}_ADD_STARTED`,
  ADD_SUCCESS: `${prefix}_ADD_SUCCESS`,
  ADD_ERROR: `${prefix}_ADD_ERROR`,

  UPDATE_STARTED: `${prefix}_UPDATE_STARTED`,
  UPDATE_SUCCESS: `${prefix}_UPDATE_SUCCESS`,
  UPDATE_ERROR: `${prefix}_UPDATE_ERROR`,

  doInit: (id?) => async (dispatch) => {
    try {
      dispatch({
        type: proveedoresFormActions.INIT_STARTED,
      });

      const isEdit = Boolean(id);
      let record = {};

      if (isEdit) {
        record = await ProveedoresService.find(id);
      }

      dispatch({
        type: proveedoresFormActions.INIT_SUCCESS,
        payload: record,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: proveedoresFormActions.INIT_ERROR,
      });

      getHistory().push('/proveedores');
    }
  },
  doAdd: (values) => async (dispatch) => {
    try {
      dispatch({
        type: proveedoresFormActions.ADD_STARTED,
      });

      await ProveedoresService.create(values);

      dispatch({
        type: proveedoresFormActions.ADD_SUCCESS,
      });

      Message.success(i18n('Proveedor agregado correctamente'));

      getHistory().push('/proveedores');
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: proveedoresFormActions.ADD_ERROR,
      });
    }
  },

  doUpdate: (values, id) => async (dispatch, getState) => {
    try {
      dispatch({
        type: proveedoresFormActions.UPDATE_STARTED,
      });

      await ProveedoresService.edit(values, id);

      dispatch({
        type: proveedoresFormActions.UPDATE_SUCCESS,
      });

      const currentproveedores = authSelectors.selectCurrentUser(
        getState(),
      );

      if (currentproveedores.id === values.id) {
        await dispatch(authActions.doRefreshCurrentUser());
      }

      Message.success(i18n('Proveedor modificado correctamente'));

      getHistory().push('/proveedores');
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: proveedoresFormActions.UPDATE_ERROR,
      });
    }
  },
};

export default proveedoresFormActions;

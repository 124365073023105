import list from 'src/modules/desplazamientos/list/desplazamientosListReducers';
import form from 'src/modules/desplazamientos/form/desplazamientosFormReducers';
import view from 'src/modules/desplazamientos/view/desplazamientosViewReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
});

import authAxios from 'src/modules/shared/axios/authAxios';

export default class ListasKelvineuService {
  static async edit(data, id) {
   

    const response = await authAxios.put(
      `/materiales/${id}`,
      data,
    );

    return response.data;
  }

  static async destroy(ids) {
    const params = {
      ids,
    };

    const response = await authAxios.delete(
      `/materiales/`,
      {
        params,
      },
    );

    return response.data;
  }

  static async create(data) {

    const response = await authAxios.post(
      `/materiales`,
      data,
    );

    return response.data;
  }

  static async find(id) {
    const response = await authAxios.get(
      `/materiales/${id}`,
    );
    return response.data;
  }

  static async fetchListasKelvineu(filter, orderBy, limit, offset) {
    const params = {
      filter,
      sort: orderBy,
      size: limit,
      page: offset,
    };

    const response = await authAxios.get(
      `/materiales/`,
      {
        params,
      },
    );

    return response.data;
  }

  static async fetchListasKelvineuAutocomplete(query, limit) {
    const params = {
      query,
      limit,
    };

    const response = await authAxios.get(
      `/materiales/autocomplete`,
      {
        params,
      },
    );
    return response.data;
  }
}

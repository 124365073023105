import list from 'src/modules/descuentos/list/descuentosListReducers';
import form from 'src/modules/descuentos/form/descuentosFormReducers';
import view from 'src/modules/descuentos/view/descuentosViewReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
});

import list from 'src/modules/listasKelvineu/list/listasKelvineuListReducers';
import form from 'src/modules/listasKelvineu/form/listasKelvineuFormReducers';
import view from 'src/modules/listasKelvineu/view/listasKelvineuViewReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
});

import authAxios from 'src/modules/shared/axios/authAxios';

export default class MdoService {
  static async edit(data, id) {
   
    const response = await authAxios.put(
      `/manoDeObra/${id}`,
      data,
    );

    return response.data;
  }

  static async destroy(id) {
   

    const response = await authAxios.delete(
      `/manoDeObra/${id}`,
    );

    return response.data;
  }

  static async create(data) {

    const response = await authAxios.post(
      `/manoDeObra`,
      data,
    );

    return response.data;
  }
  static async createManoDeObra(data) {

    const response = await authAxios.post(
      `/manoDeObra`,
      data,
    );

    return response.data;
  }
  static async find(id) {
    const response = await authAxios.get(
      `/manoDeObra/${id}`,
    );
    return response.data;
  }
  static async fetch(filter, orderBy, limit, offset) {
    const params = {
      filter,
      sort: orderBy,
      size: limit,
      page: offset,
    };

    const response = await authAxios.get(
      `/manoDeObra/`,
      {
        params,
      },
    );

    return response.data;
  }
  static async fetchTipoMdo(filter, orderBy, limit, offset) {
    const params = {
      filter,
      sort: orderBy,
      size: limit,
      page: offset,
    };

    const response = await authAxios.get(
      `/manoDeObra/tipos`,
      {
        params,
      },
    );

    return response.data;
  }
  static async fetchMdoAutocomplete(query, limit) {
    const params = {
      query,
      limit,
    };

    const response = await authAxios.get(
      `/manoDeObra/autocomplete`,
      {
        params,
      },
    );
    return response.data;
  }
}

import list from 'src/modules/capitulos/list/capitulosListReducers';
import form from 'src/modules/capitulos/form/capitulosFormReducers';
import view from 'src/modules/capitulos/view/capitulosViewReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
});

import Errors from 'src/modules/shared/error/errors';
import { getHistory } from 'src/modules/store';
import desplazamientosService from 'src/modules/desplazamientos/desplazamientosService';

const prefix = 'DESPLAZAMIENTOS_VIEW';

const desplazamientosViewActions = {
  FIND_STARTED: `${prefix}_FIND_STARTED`,
  FIND_SUCCESS: `${prefix}_FIND_SUCCESS`,
  FIND_ERROR: `${prefix}_FIND_ERROR`,

  doFind: (id) => async (dispatch) => {
    try {
      dispatch({
        type: desplazamientosViewActions.FIND_STARTED,
      });

      const desplazamientos = await desplazamientosService.find(id);

      dispatch({
        type: desplazamientosViewActions.FIND_SUCCESS,
        payload: desplazamientos,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: desplazamientosViewActions.FIND_ERROR,
      });

      getHistory().push('/desplazamientos');
    }
  },
};

export default desplazamientosViewActions;

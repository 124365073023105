import Errors from 'src/modules/shared/error/errors';
import Message from 'src/view/shared/message';
import PresupuestoService from 'src/modules/presupuesto/presupuestoService';
import { getHistory } from 'src/modules/store';
import { i18n } from 'src/i18n';
import authSelectors from 'src/modules/auth/authSelectors';
import authActions from 'src/modules/auth/authActions';

const prefix = 'PRESUPUESTO_FORM';

const presupuestoFormActions = {
  INIT_STARTED: `${prefix}_INIT_STARTED`,
  INIT_SUCCESS: `${prefix}_INIT_SUCCESS`,
  INIT_ERROR: `${prefix}_INIT_ERROR`,

  ADD_STARTED: `${prefix}_ADD_STARTED`,
  ADD_SUCCESS: `${prefix}_ADD_SUCCESS`,
  ADD_ERROR: `${prefix}_ADD_ERROR`,

  UPDATE_STARTED: `${prefix}_UPDATE_STARTED`,
  UPDATE_SUCCESS: `${prefix}_UPDATE_SUCCESS`,
  UPDATE_ERROR: `${prefix}_UPDATE_ERROR`,

  doInit: (id?) => async (dispatch) => {
    try {
      dispatch({
        type: presupuestoFormActions.INIT_STARTED,
      });

      const isEdit = Boolean(id);
      let record = {};

      if (isEdit) {
        record = await PresupuestoService.find(id);
      }

      dispatch({
        type: presupuestoFormActions.INIT_SUCCESS,
        payload: record,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: presupuestoFormActions.INIT_ERROR,
      });

      getHistory().push('/presupuesto');
    }
  },
  doFile: (id?) => async (dispatch) => {
    try {
      dispatch({
        type: presupuestoFormActions.INIT_STARTED,
      });

      const isEdit = Boolean(id);
      let record = {};

      if (isEdit) {
        record = await PresupuestoService.findFile(id);
      }

      dispatch({
        type: presupuestoFormActions.INIT_SUCCESS,
        payload: record,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: presupuestoFormActions.INIT_ERROR,
      });

      getHistory().push('/presupuesto');
    }
  },

  doAdd: (values) => async (dispatch) => {
    try {
      dispatch({
        type: presupuestoFormActions.ADD_STARTED,
      });

      await PresupuestoService.create(values);

      dispatch({
        type: presupuestoFormActions.ADD_SUCCESS,
      });

      Message.success(i18n('Presupuesto agregado correctamente'));

      getHistory().push('/presupuesto');
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: presupuestoFormActions.ADD_ERROR,
      });
    }
  },

  doUpdate: (values, id) => async (dispatch, getState) => {
    try {
      dispatch({
        type: presupuestoFormActions.UPDATE_STARTED,
      });

      await PresupuestoService.edit(values, id);

      dispatch({
        type: presupuestoFormActions.UPDATE_SUCCESS,
      });

      const currentPresupuesto = authSelectors.selectCurrentUser(
        getState(),
      );

      if (currentPresupuesto.id === values.id) {
        await dispatch(authActions.doRefreshCurrentUser());
      }

      Message.success(i18n('Presupuesto modificado correctamente'));

      getHistory().push('/presupuesto');
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: presupuestoFormActions.UPDATE_ERROR,
      });
    }
  },
};

export default presupuestoFormActions;

import Errors from 'src/modules/shared/error/errors';
import { getHistory } from 'src/modules/store';
import descuentosService from 'src/modules/descuentos/descuentosService';

const prefix = 'DESCUENTOS_VIEW';

const descuentosViewActions = {
  FIND_STARTED: `${prefix}_FIND_STARTED`,
  FIND_SUCCESS: `${prefix}_FIND_SUCCESS`,
  FIND_ERROR: `${prefix}_FIND_ERROR`,

  doFind: (id) => async (dispatch) => {
    try {
      dispatch({
        type: descuentosViewActions.FIND_STARTED,
      });

      const descuentos = await descuentosService.find(id);

      dispatch({
        type: descuentosViewActions.FIND_SUCCESS,
        payload: descuentos,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: descuentosViewActions.FIND_ERROR,
      });

      getHistory().push('/descuentos');
    }
  },
};

export default descuentosViewActions;

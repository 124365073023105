import Errors from 'src/modules/shared/error/errors';
import { getHistory } from 'src/modules/store';
import listasService from 'src/modules/listas/listasService';

const prefix = 'LISTAS_VIEW';

const listasViewActions = {
  FIND_STARTED: `${prefix}_FIND_STARTED`,
  FIND_SUCCESS: `${prefix}_FIND_SUCCESS`,
  FIND_ERROR: `${prefix}_FIND_ERROR`,

  doFind: (id) => async (dispatch) => {
    try {
      dispatch({
        type: listasViewActions.FIND_STARTED,
      });

      const listas = await listasService.find(id);

      dispatch({
        type: listasViewActions.FIND_SUCCESS,
        payload: listas,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: listasViewActions.FIND_ERROR,
      });

      getHistory().push('/listas-proveedores');
    }
  },
};

export default listasViewActions;

import authAxios from 'src/modules/shared/axios/authAxios';

export default class ProveedoresService {
  static async edit(data, id) {
   
    const response = await authAxios.put(
      `/proveedores/${id}`,
      data,
    );

    return response.data;
  }

  static async destroy(id) {
   

    const response = await authAxios.delete(
      `/proveedores/${id}`,
    );

    return response.data;
  }

  static async create(data) {

    const response = await authAxios.post(
      `/proveedores`,
      data,
    );

    return response.data;
  }

  static async find(id) {
    const response = await authAxios.get(
      `/proveedores/${id}`,
    );
    return response.data;
  }

  static async fetchProveedores(filter, orderBy, limit, offset) {
    const params = {
      filter,
      sort: orderBy,
      size: limit,
      page: offset,
    };

    const response = await authAxios.get(
      `/proveedores/`,
      {
        params,
      },
    );

    return response.data;
  }
  static async fetchAll(filter, orderBy, limit, offset) {
    const params = {
      filter,
      sort: orderBy,
      size: limit,
      page: offset,
    };

    const response = await authAxios.get(
      `/proveedores/all`,
      {
        params,
      },
    );

    return response.data;
  }
  static async fetchProveedoresOptions(filter, orderBy, limit, offset) {
    const params = {
      filter,
      sort: orderBy,
      size: limit,
      page: offset,
    };

    const response = await authAxios.get(
      `/proveedores/options`,
      {
        params,
      },
    );

    return response.data;
  }
  static async fetchTipoProveedores(filter, orderBy, limit, offset) {
    const params = {
      filter,
      sort: orderBy,
      size: limit,
      page: offset,
    };

    const response = await authAxios.get(
      `/proveedores/tipos`,
      {
        params,
      },
    );

    return response.data;
  }
  static async fetchProveedoresAutocomplete(query, limit) {
    const params = {
      query,
      limit,
    };

    const response = await authAxios.get(
      `/proveedores/autocomplete`,
      {
        params,
      },
    );
    return response.data;
  }
  static async import( data) {

    const response = await authAxios.post(
      `/proveedores/alta_masiva/`,
      data,
    );
    
    return response.data;
  }
}

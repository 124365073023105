import authAxios from 'src/modules/shared/axios/authAxios';

export default class ConfigService {
  static async edit(data, id) {
   
    const response = await authAxios.put(
      `/config/${id}`,
      data,
    );

    return response.data;
  }

  static async destroy(id) {
   

    const response = await authAxios.delete(
      `/config/${id}`,
    );

    return response.data;
  }

  static async create(data) {

    const response = await authAxios.post(
      `/config`,
      data,
    );

    return response.data;
  }

  static async find(id) {
    const response = await authAxios.get(
      `/config/${id}`,
    );
    return response.data;
  }

  static async fetchConfig(filter, orderBy, limit, offset) {
    const params = {
      filter,
      sort: orderBy,
      size: limit,
      page: offset,
    };

    const response = await authAxios.get(
      `/config/`,
      {
        params,
      },
    );

    return response.data;
  }

  static async fetchConfigAutocomplete(query, limit) {
    const params = {
      query,
      limit,
    };

    const response = await authAxios.get(
      `/config/autocomplete`,
      {
        params,
      },
    );
    return response.data;
  }
}

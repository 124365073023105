import ProveedoresService from 'src/modules/proveedores/proveedoresService';
import selectors from 'src/modules/proveedores/list/proveedoresListSelectors';
import Errors from 'src/modules/shared/error/errors';
import Message from 'src/view/shared/message';
import { i18n } from 'src/i18n';

const prefix = 'PROVEEDORES_LIST';

const proveedoresListActions = {
  FETCH_STARTED: `${prefix}_FETCH_STARTED`,
  FETCH_SUCCESS: `${prefix}_FETCH_SUCCESS`,
  FETCH_ERROR: `${prefix}_FETCH_ERROR`,

  RESETED: `${prefix}_RESETED`,
  TOGGLE_ONE_SELECTED: `${prefix}_TOGGLE_ONE_SELECTED`,
  TOGGLE_ALL_SELECTED: `${prefix}_TOGGLE_ALL_SELECTED`,
  CLEAR_ALL_SELECTED: `${prefix}_CLEAR_ALL_SELECTED`,

  PAGINATION_CHANGED: `${prefix}_PAGINATION_CHANGED`,
  SORTER_CHANGED: `${prefix}_SORTER_CHANGED`,

  DESTROY_ALL_SELECTED_STARTED: `${prefix}_DESTROY_ALL_SELECTED_STARTED`,
  DESTROY_ALL_SELECTED_SUCCESS: `${prefix}_DESTROY_ALL_SELECTED_SUCCESS`,
  DESTROY_ALL_SELECTED_ERROR: `${prefix}_DESTROY_ALL_SELECTED_ERROR`,

  DESTROY_STARTED: `${prefix}_DESTROY_STARTED`,
  DESTROY_SUCCESS: `${prefix}_DESTROY_SUCCESS`,
  DESTROY_ERROR: `${prefix}_DESTROY_ERROR`,

  IMPORT_STARTED: `${prefix}_IMPORT_STARTED`,
  IMPORT_SUCCESS: `${prefix}_IMPORT_SUCCESS`,
  IMPORT_ERROR: `${prefix}_IMPORT_ERROR`,

  doClearAllSelected() {
    return {
      type: proveedoresListActions.CLEAR_ALL_SELECTED,
    };
  },

  doToggleAllSelected() {
    return {
      type: proveedoresListActions.TOGGLE_ALL_SELECTED,
    };
  },

  doToggleOneSelected(id) {
    return {
      type: proveedoresListActions.TOGGLE_ONE_SELECTED,
      payload: id,
    };
  },

  doReset: () => async (dispatch) => {
    dispatch({
      type: proveedoresListActions.RESETED,
    });

    dispatch(proveedoresListActions.doFetch());
  },

  doChangePagination: (pagination) => async (
    dispatch,
    getState,
  ) => {
    dispatch({
      type: proveedoresListActions.PAGINATION_CHANGED,
      payload: pagination,
    });

    dispatch(proveedoresListActions.doFetchCurrentFilter());
  },

  doChangeSort: (sorter) => async (dispatch, getState) => {
    dispatch({
      type: proveedoresListActions.SORTER_CHANGED,
      payload: sorter,
    });

    dispatch(proveedoresListActions.doFetchCurrentFilter());
  },

  doFetchCurrentFilter: () => async (
    dispatch,
    getState,
  ) => {
    const filter = selectors.selectFilter(getState());
    const rawFilter = selectors.selectRawFilter(getState());
    dispatch(proveedoresListActions.doFetch(filter, rawFilter, true));
  },

  doFetch: (filter?, rawFilter?, keepPagination = false) => async (
    dispatch,
    getState,
  ) => {
    try {
      dispatch({
        type: proveedoresListActions.FETCH_STARTED,
        payload: { filter, rawFilter, keepPagination },
      });

      const response = await ProveedoresService.fetchProveedores(
        filter,
        selectors.selectOrderBy(getState()),
        selectors.selectLimit(getState()),
        selectors.selectOffset(getState()),
      );
      dispatch({
        type: proveedoresListActions.FETCH_SUCCESS,
        payload: {
          rows: response.rows,
          count: response.count,
        },
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: proveedoresListActions.FETCH_ERROR,
      });
    }
  }, 
  doFetchAll: (filter?, rawFilter?, keepPagination = false) => async (
    dispatch,
    getState,
  ) => {
    try {
      dispatch({
        type: proveedoresListActions.FETCH_STARTED,
        payload: { filter, rawFilter, keepPagination },
      });

      const response = await ProveedoresService.fetchAll(
        filter,
        selectors.selectOrderBy(getState()),
        selectors.selectLimit(getState()),
        selectors.selectOffset(getState()),
      );
      dispatch({
        type: proveedoresListActions.FETCH_SUCCESS,
        payload: {
          rows: response.rows,
          count: response.count,
        },
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: proveedoresListActions.FETCH_ERROR,
      });
    }
  },
  doFetchOptions: (filter?, rawFilter?,options?, keepPagination = false) => async (
    dispatch,
    getState,
  ) => {
    try {
      dispatch({
        type: proveedoresListActions.FETCH_STARTED,
        payload: { filter, rawFilter, keepPagination },
      });

      const response = await ProveedoresService.fetchProveedoresOptions(
        filter,
        selectors.selectOrderBy(getState()),
        options ? null : selectors.selectLimit(getState()),
        selectors.selectOffset(getState()),
      );
      dispatch({
        type: proveedoresListActions.FETCH_SUCCESS,
        payload: {
          rows: response.rows,
          count: response.count,
        },
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: proveedoresListActions.FETCH_ERROR,
      });
    }
  },
  doDestroy: (id, restore) => async (dispatch, getState) => {
    try {
      dispatch({
        type: proveedoresListActions.DESTROY_STARTED,
      });

      await ProveedoresService.destroy(id);

      dispatch({
        type: proveedoresListActions.DESTROY_SUCCESS,
      });
      
      if (restore) {
        Message.success(i18n('Proveedor habilitado exitosamente'));
      } else {Message.success(i18n('Proveedor inhabilitado exitosamente')); }
     

      dispatch(proveedoresListActions.doFetchCurrentFilter());
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: proveedoresListActions.DESTROY_ERROR,
      });

      dispatch(proveedoresListActions.doFetchCurrentFilter());
    }
  },

  doDestroyAllSelected: () => async (
    dispatch,
    getState,
  ) => {
    try {
      const selectedRows = selectors.selectSelectedRows(
        getState(),
      );

      dispatch({
        type: proveedoresListActions.DESTROY_ALL_SELECTED_STARTED,
      });

      await ProveedoresService.destroy(
        selectedRows.map((row) => row.id),
      );

      dispatch({
        type: proveedoresListActions.DESTROY_ALL_SELECTED_SUCCESS,
      });

      Message.success(
        i18n('proveedores.doDestroyAllSelectedSuccess'),
      );

      dispatch(proveedoresListActions.doFetchCurrentFilter());
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: proveedoresListActions.DESTROY_ALL_SELECTED_ERROR,
      });

      dispatch(proveedoresListActions.doFetchCurrentFilter());
    }
  }, 
  doImport: (data) => async (dispatch) => {
    try {
      dispatch({
        type: proveedoresListActions.IMPORT_STARTED,
      });

      await ProveedoresService.import(data);
     

      dispatch({
        type: proveedoresListActions.IMPORT_SUCCESS,
      });

      
      (window as any).location.reload();

      Message.success(i18n('importer.completed.success'),);

    } catch (error) {
      Errors.handle('Error en el alta');
      console.log(error)
      dispatch({
        type: proveedoresListActions.IMPORT_ERROR,
      });
    }
  },
};

export default proveedoresListActions;

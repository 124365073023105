import list from 'src/modules/proveedores/list/proveedoresListReducers';
import form from 'src/modules/proveedores/form/proveedoresFormReducers';
import view from 'src/modules/proveedores/view/proveedoresViewReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
});

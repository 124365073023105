import { connectRouter } from 'connected-react-router';
import layout from 'src/modules/layout/layoutReducers';
import auth from 'src/modules/auth/authReducers';
import user from 'src/modules/user/userReducers';
import capitulos from 'src/modules/capitulos/capitulosReducers';
import listas from 'src/modules/listas/listasReducers';
import listasKelvineu from 'src/modules/listasKelvineu/listasKelvineuReducers';
import config from 'src/modules/config/configReducers';
import desplazamientos from 'src/modules/desplazamientos/desplazamientosReducers';
import descuentos from 'src/modules/descuentos/descuentosReducers';
import proveedores from 'src/modules/proveedores/proveedoresReducers';
import mdo from 'src/modules/mdo/mdoReducers';
import compuestos from 'src/modules/compuestos/compuestosReducers';
import presupuesto from 'src/modules/presupuesto/presupuestoReducers';
import { combineReducers } from 'redux';

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    layout,
    auth,
    user,
    capitulos,
    listas,
    listasKelvineu,
    config,
    desplazamientos,
    descuentos,
    proveedores,
    mdo,
    compuestos,
    presupuesto
  });

import list from 'src/modules/config/list/configListReducers';
import form from 'src/modules/config/form/configFormReducers';
import view from 'src/modules/config/view/configViewReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
});
